var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("NcButton", {
    attrs: {
      type: "primary",
      "native-type": "submit",
      wide: true
    },
    on: {
      click: function ($event) {
        return _vm.$emit("click");
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_vm.loading ? _c("div", {
          staticClass: "submit-wrapper__icon icon-loading-small-dark"
        }) : _c("ArrowRight", {
          staticClass: "submit-wrapper__icon"
        })];
      },
      proxy: true
    }])
  }, [_vm._v("\n\t" + _vm._s(!_vm.loading ? _vm.value : _vm.valueLoading) + "\n\t")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };