import NcButton from '@nextcloud/vue/dist/Components/NcButton.js';
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue';
export default {
  name: 'LoginButton',
  components: {
    ArrowRight,
    NcButton
  },
  props: {
    value: {
      type: String,
      default: t('core', 'Log in')
    },
    valueLoading: {
      type: String,
      default: t('core', 'Logging in …')
    },
    loading: {
      type: Boolean,
      required: true
    },
    invertedColors: {
      type: Boolean,
      default: false
    }
  }
};