// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form[data-v-a10057b0] {
  text-align: left;
  font-size: 1rem;
}
.login-form__fieldset[data-v-a10057b0] {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.login-form__link[data-v-a10057b0] {
  display: block;
  font-weight: normal !important;
  padding-bottom: 1rem;
  cursor: pointer;
  font-size: var(--default-font-size);
  text-align: center;
  padding: 0.5rem 1rem 1rem 1rem;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
