var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return (_vm.isHttps || _vm.isLocalhost) && _vm.hasPublicKeyCredential ? _c("form", {
    ref: "loginForm",
    attrs: {
      method: "post",
      name: "login"
    },
    on: {
      submit: function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c("h2", [_vm._v(_vm._s(_vm.t("core", "Log in with a device")))]), _vm._v(" "), _c("fieldset", [_c("NcTextField", {
    attrs: {
      required: "",
      value: _vm.user,
      autocomplete: _vm.autoCompleteAllowed ? "on" : "off",
      error: !_vm.validCredentials,
      label: _vm.t("core", "Username or email"),
      placeholder: _vm.t("core", "Username or email"),
      "helper-text": !_vm.validCredentials ? _vm.t("core", "Your account is not setup for passwordless login.") : ""
    },
    on: {
      "update:value": _vm.changeUsername
    }
  }), _vm._v(" "), _vm.validCredentials ? _c("LoginButton", {
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: _vm.authenticate
    }
  }) : _vm._e()], 1)]) : !_vm.hasPublicKeyCredential ? _c("div", {
    staticClass: "update"
  }, [_c("InformationIcon", {
    attrs: {
      size: "70"
    }
  }), _vm._v(" "), _c("h2", [_vm._v(_vm._s(_vm.t("core", "Browser not supported")))]), _vm._v(" "), _c("p", {
    staticClass: "infogroup"
  }, [_vm._v("\n\t\t" + _vm._s(_vm.t("core", "Passwordless authentication is not supported in your browser.")) + "\n\t")])], 1) : !_vm.isHttps && !_vm.isLocalhost ? _c("div", {
    staticClass: "update"
  }, [_c("LockOpenIcon", {
    attrs: {
      size: "70"
    }
  }), _vm._v(" "), _c("h2", [_vm._v(_vm._s(_vm.t("core", "Your connection is not secure")))]), _vm._v(" "), _c("p", {
    staticClass: "infogroup"
  }, [_vm._v("\n\t\t" + _vm._s(_vm.t("core", "Passwordless authentication is only available over a secure connection.")) + "\n\t")])], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };