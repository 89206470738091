var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("form", {
    staticClass: "login-form",
    on: {
      submit: function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c("fieldset", {
    staticClass: "login-form__fieldset"
  }, [_c("NcTextField", {
    attrs: {
      id: "user",
      value: _vm.user,
      name: "user",
      maxlength: 255,
      autocapitalize: "off",
      label: _vm.t("core", "Account name or email"),
      required: ""
    },
    on: {
      "update:value": function ($event) {
        _vm.user = $event;
      },
      change: _vm.updateUsername
    }
  }), _vm._v(" "), _c("LoginButton", {
    attrs: {
      value: _vm.t("core", "Reset password")
    }
  }), _vm._v(" "), _vm.message === "send-success" ? _c("NcNoteCard", {
    attrs: {
      type: "success"
    }
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.t("core", "If this account exists, a password reset message has been sent to its email address. If you do not receive it, verify your email address and/or account name, check your spam/junk folders or ask your local administration for help.")) + "\n\t\t")]) : _vm.message === "send-error" ? _c("NcNoteCard", {
    attrs: {
      type: "error"
    }
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.t("core", "Couldn't send reset email. Please contact your administrator.")) + "\n\t\t")]) : _vm.message === "reset-error" ? _c("NcNoteCard", {
    attrs: {
      type: "error"
    }
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.t("core", "Password cannot be changed. Please contact your administrator.")) + "\n\t\t")]) : _vm._e(), _vm._v(" "), _c("a", {
    staticClass: "login-form__link",
    attrs: {
      href: "#"
    },
    on: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.$emit("abort");
      }
    }
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.t("core", "Back to login")) + "\n\t\t")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };