import axios from '@nextcloud/axios';
import { generateUrl } from '@nextcloud/router';
import LoginButton from './LoginButton.vue';
import NcTextField from '@nextcloud/vue/dist/Components/NcTextField.js';
import NcNoteCard from '@nextcloud/vue/dist/Components/NcNoteCard.js';
import AuthMixin from '../../mixins/auth.js';
export default {
  name: 'ResetPassword',
  components: {
    LoginButton,
    NcNoteCard,
    NcTextField
  },
  mixins: [AuthMixin],
  props: {
    username: {
      type: String,
      required: true
    },
    resetPasswordLink: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: false,
      loading: false,
      message: undefined,
      user: this.username
    };
  },
  watch: {
    username(value) {
      this.user = value;
    }
  },
  methods: {
    updateUsername() {
      this.$emit('update:username', this.user);
    },
    submit() {
      this.loading = true;
      this.error = false;
      this.message = '';
      const url = generateUrl('/lostpassword/email');
      const data = {
        user: this.user
      };
      return axios.post(url, data).then(resp => resp.data).then(data => {
        if (data.status !== 'success') {
          throw new Error("got status ".concat(data.status));
        }
        this.message = 'send-success';
      }).catch(e => {
        console.error('could not send reset email request', e);
        this.error = true;
        this.message = 'send-error';
      }).then(() => {
        this.loading = false;
      });
    }
  }
};