import { render, staticRenderFns } from "./LoginButton.vue?vue&type=template&id=2b0f9fce&scoped=true"
import script from "./LoginButton.vue?vue&type=script&lang=js"
export * from "./LoginButton.vue?vue&type=script&lang=js"
import style0 from "./LoginButton.vue?vue&type=style&index=0&id=2b0f9fce&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b0f9fce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/shgk/nextcloud/stable28/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b0f9fce')) {
      api.createRecord('2b0f9fce', component.options)
    } else {
      api.reload('2b0f9fce', component.options)
    }
    module.hot.accept("./LoginButton.vue?vue&type=template&id=2b0f9fce&scoped=true", function () {
      api.rerender('2b0f9fce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "core/src/components/login/LoginButton.vue"
export default component.exports